import Background from '../../../assets/section/section2/background.png';
import Board from '../../../components/items/Board';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fadeDown } from '../../../styles/animation/Animation';

const Section2 = () => {
  const ref = useInView();
  return (
    <section className=" section2 wrapper position-relative d-flex justify-content-center">
      <div className="container row" id="Overview">
        <motion.div
          className="section2_content w-100 col-10"
          ref={ref}
          variants={fadeDown}
          initial="hidden"
          whileInView="visible"
        >
          <Board
            title="About us"
            isLightBoard={true}
            content="Every great kingdom starts by planting a seed. Welcome to the exciting world of **Farm Kingdom**, the digital farm game running on the Blue20 network of Genesys Network. In Farm Kingdom, every blade of grass sprouting becomes an exciting money-making opportunity. You can mine coins, expand your farm, and create unique NFTs with each success./n Join now to explore the vibrant world of Play-to-Earn, where creativity and earning money blend seamlessly. Get ready to elevate your farm to new heights on the Blue20 network!/n In this innovative play-to-earn challenge, every action you take brings you closer to new heights of success. Whether you're cultivating wheat or raising magical animals, every achievement in your farm allows you to create unique NFTs, giving you the opportunity to own and trade exclusive digital assets./n Not only will you have fun, but you'll also plant the seeds for a prosperous new future. Develop your creativity, establish your strategy, and watch your farm transform into a thriving kingdom. Your kingdom starts here, and it won't just be virtual./n Make sure you're ready to elevate your farm to new heights on Genesys Network!"
          ></Board>
        </motion.div>
      </div>
    </section>
  );
};
export default Section2;
