import ContentImg from '../../assets/components/ContentBoard.png';

const ContentBoard = ({ content }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-start board_container"
      style={{
        backgroundImage: `url(${ContentImg})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* <img
        src={ContentImg}
        alt="ContentBoard"
        className="d-none d-lg-block img-fluid h-auto"
      /> */}

      <div className="board_content  text-center w-100 d-flex justify-content-center ">
          {content.split('/n').map((value) => (
            <li className="board-content_text w-100 ">{value}</li>
          ))}
      </div>
    </div>
  );
};
export default ContentBoard;
